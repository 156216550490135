import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, ProgressBar, Card } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import useDocumentTitle from '../../../Utils/useDocumentTitle';

function Signup({ pageTitle }) {
    useDocumentTitle(pageTitle, "Sign In | The Attar Co.");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthText, setPasswordStrengthText] = useState('');
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Show/hide password toggle state
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Show/hide confirm password toggle state
    const navigate = useNavigate();

    const calculatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[0-9]/.test(password)) strength += 1;
        if (/[^a-zA-Z0-9]/.test(password)) strength += 1;

        setPasswordStrength(strength);

        if (strength === 5) {
            setPasswordStrengthText('Strong');
        } else if (strength >= 3) {
            setPasswordStrengthText('Medium');
        } else {
            setPasswordStrengthText('Weak');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setShowPasswordStrength(newPassword.length > 0);
        calculatePasswordStrength(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);

        if (password && newConfirmPassword !== password) {
            setPasswordMatchError('Passwords do not match.');
        } else {
            setPasswordMatchError('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

        if (
            form.checkValidity() === false ||
            password !== confirmPassword ||
            !passwordRegex.test(password)
        ) {
            event.stopPropagation();
            if (password !== confirmPassword) {
                setError('Passwords do not match.');
            } else if (!passwordRegex.test(password)) {
                setError('Password must be at least 8 characters long and include a combination of letters, numbers, and special characters.');
            }
        } else {
            try {
                const response = await axios.post('https://theattarco.com/api/client_api/authentication/register/index.php', {
                    firstName,
                    lastName,
                    email,
                    password,
                });

                if (response.data.success) {
                    console.log('Account creation successful:', response.data);
                    navigate('/auth/login', { state: { successMessage: 'Account created successfully. A verification email has been sent to ' + email + ', please verify to login' } });
                } else {
                    setError('Account creation failed. Please try again.');
                }

            } catch (err) {
                console.error('Account creation failed:', err);
                setError('Account creation failed. Please try again.');
            }
        }

        setValidated(true);
    };

    const handleLogin = () => {
        navigate('/auth/login');
    };

    // Toggle show/hide password
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    // Disable the Create Account button if any validation is not met
    const isFormValid = () => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
        return (
            firstName &&
            lastName &&
            email &&
            password &&
            confirmPassword &&
            password === confirmPassword &&
            passwordRegex.test(password)
        );
    };

    return (
        <Container className="sectionView d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
                <Col md={6} className="mx-auto">
                    <h2 className="text-center text-white mb-4">Create Account</h2>
                    <Card>
                        <Card.Body className='p-lg-5 py-5'>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        required
                                        className='rounded-5'
                                        type="text"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide your first name.
                                    </Form.Control.Feedback>
                                </Form.Floating>

                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        required
                                        type="text"
                                        className='rounded-5'
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide your last name.
                                    </Form.Control.Feedback>
                                </Form.Floating>

                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        required
                                        className='rounded-5'
                                        type="email"
                                        id="email"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Label htmlFor="email">Email address</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </Form.Floating>

                                <Form.Floating className="mb-3 position-relative">
                                    <Form.Control
                                        required
                                        className='rounded-5'
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        minLength={8}
                                    />
                                    <Form.Label htmlFor="password">Password</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Password must be at least 8 characters long and include letters, numbers, and special characters.
                                    </Form.Control.Feedback>
                                    <span onClick={toggleShowPassword} className="position-absolute end-0 top-50 translate-middle-y me-5">
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </Form.Floating>
                                {showPasswordStrength && (
                                    <div className="my-2">
                                        <ProgressBar now={(passwordStrength / 5) * 100} variant={passwordStrengthText === 'Strong' ? 'success' : passwordStrengthText === 'Medium' ? 'warning' : 'danger'} />
                                        <small className="text-muted">{passwordStrengthText}</small>
                                    </div>
                                )}
                                <Form.Floating className="mb-3 position-relative">
                                    <Form.Control
                                        required
                                        className='rounded-5'
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="confirmPassword"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please confirm your password.
                                    </Form.Control.Feedback>
                                    <span onClick={toggleShowConfirmPassword} className="position-absolute end-0 top-50 translate-middle-y me-5">
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </Form.Floating>
                                {passwordMatchError && (
                                    <div className="text-danger my-2">
                                        {passwordMatchError}
                                    </div>
                                )}
                                <Button variant="dark" type="submit" className="rounded-5 py-3 w-100 mb-3" disabled={!isFormValid()}>
                                    Create Account
                                </Button>

                                <Button variant="outline-dark" className="rounded-5 py-3 w-100 mt-3" onClick={handleLogin}>
                                    Sign in
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Signup;
import axios from 'axios';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Sliders.css';
import { Link } from 'react-router-dom';

const Sliders = () => {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  const baseURL = 'https://theattarco.com/';

  useEffect(() => {
    getSliders();
  }, []);

  const getSliders = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/client_api/Sliders/index.php`);
      setSliders(response.data);
    } catch (error) {
      setError('Failed to load sliders');
      console.error(error);
    } finally {
      setLoading(false); // Ensure loading ends whether successful or not
    }
  };

  // Memoize slider settings to avoid re-creation
  const sliderSettings = useMemo(() => ({
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
  }), []);

  // Memoize generateSlug function
  const generateSlug = useCallback((name) => {
    return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }, []);

  // Early return if there's an error
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      <section>
        {/* Loading state */}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="sliderDesktop mt-3">
              <Container fluid className="p-0">
                <Slider {...sliderSettings}>
                  {sliders.map((item, index) => (
                    <Link
                      to={`${item.slider_type === 'product'
                        ? `/${item.slider_type}/${generateSlug(item.additional_data.product)}`
                        : (item.slider_type === 'promotion' ? '/' : `/${item.slider_type}/${generateSlug(item.additional_data.collection)}`)}`}
                      key={index} className="slide-item"
                    >
                      <picture>
                        {/* Serve responsive images with different resolutions */}
                        <source srcSet={`${baseURL}${item.image_path}?w=1200`} media="(min-width: 1200px)" />
                        <source srcSet={`${baseURL}${item.image_path}?w=800`} media="(min-width: 800px)" />
                        <source srcSet={`${baseURL}${item.image_path}?w=600`} media="(max-width: 599px)" />
                        <img
                          src={`${baseURL}${item.image_path}?w=1200`}
                          alt={item.title || 'Slide'}
                          className='sliderImages'
                          loading="lazy" // Lazy load images
                        />
                      </picture>
                    </Link>
                  ))}
                </Slider>
              </Container>
            </div>

            <div className="sliderMobile">
              <Slider {...sliderSettings}>
                {sliders.map((item, index) => (
                  <Link
                    to={`${item.slider_type === 'product'
                      ? `/${item.slider_type}/${generateSlug(item.additional_data.product)}`
                      : (item.slider_type === 'promotion' ? '/' : `/${item.slider_type}/${generateSlug(item.additional_data.collection)}`)}`}
                    key={index} className="slide-item"
                  >
                    <picture>
                      {/* Serve responsive images for mobile */}
                      <source srcSet={`${baseURL}${item.mobile_image_path}?w=600`} media="(max-width: 599px)" />
                      <img
                        src={`${baseURL}${item.mobile_image_path}?w=600`}
                        alt={item.title || 'Slide'}
                        className='sliderImages'
                        loading="lazy" // Lazy load images
                      />
                    </picture>
                  </Link>
                ))}
              </Slider>
            </div>
          </>
        )}
      </section>
    </div>
  );
}

export default Sliders;

// useLoader.js
import { useState } from "react";

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");

  const showLoader = (msg = "Processing...", description='Please wait') => {
    setMessage(msg);
    setDescription(description);
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
    setMessage("");
    setDescription("");
  };

  return { isLoading, message, description, showLoader, hideLoader };
};

export default useLoader;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Alert, Button } from 'react-bootstrap';
import useDocumentTitle from '../../../Utils/useDocumentTitle';

function Verify({ pageTitle }) {
    useDocumentTitle(pageTitle, "Verify Email | The Attar Co.");
    const { user_id, token } = useParams(); // Extract the user_id from the URL
    const [verificationStatus, setVerificationStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // For redirecting to the login screen

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const response = await axios.post('https://theattarco.com/api/client_api/authentication/register/verify.php', {
                    user_id,
                    token
                });
                if (response.data.success) {
                    setVerificationStatus(response.data.message);
                } else {
                    setVerificationStatus(response.data.message);
                }
            } catch (err) {
                console.error('Verification error:', err);
                setVerificationStatus('Verification failed. Please try again or contact support.');
            } finally {
                setLoading(false);
            }
        };

        if (user_id) {
            verifyUser();
        }
    }, [user_id]);

    const handleLogin = () => {
        navigate('/auth/login'); // Redirects to the login screen
    };

    const handleResendLink = async () => {
        setLoading(true); // Set loading to true at the beginning of the process
        try {
            const response = await axios.post('https://theattarco.com/api/client_api/authentication/register/resendlink.php', {
                user_id,
            });
            if (response.data.success) {
                setVerificationStatus(response.data.message);
            } else {
                setVerificationStatus(response.data.message);
            }
        } catch (err) {
            console.error('Verification error:', err);
            setVerificationStatus('Error resending verification link. Please try again or contact support.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container className="sectionView d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Alert variant={verificationStatus?.includes('successfully') || verificationStatus.includes('already verified') ? 'success' : 'danger'}>
                        {verificationStatus}
                    </Alert>

                    {verificationStatus?.includes('Verification link expired') || verificationStatus?.includes('invalid or has expired') ?
                        <Button variant="outline-info" className="rounded-5 py-2 w-50 mt-3" onClick={handleResendLink}>
                            Resend Verification Link
                        </Button>
                        :
                        <Button variant="danger" className="rounded-5 py-2 w-50 mt-3" onClick={handleLogin}>
                            Sign in
                        </Button>
                    }
                </>
            )}
        </Container>
    );
}

export default Verify;

import React, { useState, useContext, useEffect } from 'react';
import { Card, Modal, Button, Row, Image, Col, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { IoAddSharp, IoClose, IoCloseCircleOutline, IoRemoveSharp, IoStar, IoStarHalf, IoStarOutline } from 'react-icons/io5';
import { TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineLoading3Quarters, AiOutlineShareAlt, AiOutlineShoppingCart } from "react-icons/ai";
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import SecureLS from "secure-ls";
import './ProductCard.css';
import Slider from 'react-slick';
import { FaFacebook, FaWhatsapp } from 'react-icons/fa';
import StarRating from '../../Utils/StarRating';
import DOMPurify from 'dompurify';

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const baseURL = 'https://theattarco.com/';
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [mainImage, setMainImage] = useState('');
    const [perfumeMainImage, setMainPerfumeImage] = useState('');
    const [selectedSize, setSelectedSize] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price.find(p => p.weight + 'ml' === selectedSize);

        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails.prodcode, quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails.prodcode, quantity });

        if (success) {
            setSwalProps({
                show: true,
                title: item.name,
                text: 'Added to Cart!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#ae8625 '
            });
        } else {
            console.error('Failed to add to cart');
            setSwalProps({
                show: true,
                title: item.name,
                text: 'Failed to add to cart!',
                icon: 'error',
                confirmButtonText: 'Try again.',
                confirmButtonColor: '#ff0000'
            });
        }
    };

    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });

        setShowLoadingIconWishlist(false);
        if (success) {
            setIsAddedWishlist(true);
        } else {
            console.error('Failed to add to wishlist');
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });

        if (success) {
            setIsAddedWishlist(false);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    const handleShowModal = () => {
        setShowModal(true);
        setMainImage(baseURL + item.images[0]);
        setMainPerfumeImage(item.perfumeimages ? baseURL + item.perfumeimages[0] : '');
        setSelectedSize(item.price[0].weight + 'ml'); // Default size
    };
    const handleCloseModal = () => setShowModal(false);

    const settings = {
        customPaging: function (i) {
            // Return an image element with the thumbnail for the custom paging
            return (
                <a>
                    <img
                        src={item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? (item.perfumeimages[i] ? baseURL + item.perfumeimages[i] : '') : (item.images[i] ? baseURL + item.images[i] : '')}
                        alt={`Thumbnail ${i}`}
                        className="slick-thumbnail me-2"
                    />
                </a>
            );
        },
        dots: true,
        // dotsClass: "slick-dots slick-thumb",
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const handleShare = async (platform) => {
        const shareData = {
            title: item.name,
            text: `Check out this product: ${item.name}. From Rs. ${item.price[0].discountedPrice}`,
            url: `${baseURL}product/${generateSlug(item?.name)}`,
        };

        const message = encodeURIComponent(
            `Check out this product: ${item.name}\nPrice: From Rs. ${item.price[0].discountedPrice}\n${baseURL}product/${generateSlug(item?.name)}`
        );

        try {
            switch (platform) {
                case "whatsapp":
                    window.open(`https://wa.me/?text=${message}`, "_blank");
                    break;
                case "instagram":
                    // Instagram doesn't have direct URL-based sharing; guide users.
                    alert("Share this link on Instagram Stories or Chats manually.");
                    break;
                case "facebook":
                    window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            shareData.url
                        )}&quote=${encodeURIComponent(shareData.text)}`,
                        "_blank"
                    );
                    break;
                case "share":
                    if (navigator.share) {
                        await navigator.share(shareData);
                        console.log("Product shared successfully!");
                    } else {
                        console.error("Web Share API not supported in this browser.");
                    }
                    break;
                default:
                    console.error("Unsupported platform.");
            }
        } catch (error) {
            console.error("Error sharing product:", error);
        }
    };

    const renderStars = (rating) => {
        const stars = [];
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        for (let i = 0; i < fullStars; i++) {
            stars.push(<IoStar key={`full-${i}`} color='gold' />);
        }

        if (halfStar) {
            stars.push(<IoStarHalf key="half" color='gold' />);
        }

        for (let i = 0; i < emptyStars; i++) {
            stars.push(<IoStarOutline key={`empty-${i}`} />);
        }

        return stars;
    };

    const Discount = ((item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice - item.price.find(p => p.weight + 'ml' === selectedSize)?.discountedPrice) / item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };

    const handleSizeChange = (size) => {
        setSelectedSize(size)
    };

    const handleQuantityChange = (change) => setQuantity(Math.max(1, quantity + change));


    return (
        <>
            <Card className='me-1 mb-1 bg-transparent' style={{ borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                <Card.Body className="p-0">
                    <div className="w-100 px-2 d-flex align-items-center justify-content-between" style={{ position: 'absolute', top: '10px', zIndex: '1' }}>
                        {item?.price?.[0]?.average_rating > 0 &&
                            <div className='bg-light rounded-3 px-2 py-1 d-flex align-items-center'>
                                <IoStar style={{ color: 'gray' }} />
                                <span className="ml-1 ms-1" style={{ color: 'gray', fontSize: '12px' }}>{String(item?.price?.[0]?.average_rating)}</span>
                            </div>
                        }
                    </div>
                    <a href={`/product/${generateSlug(item?.name)}`} className='text-decoration-none text-black'>
                        <div className="product-img-container" style={{ width: '100%' }}>
                            <Card.Img
                                src={`${baseURL}${item.images[0]}?w=10`} // Low-resolution placeholder
                                srcSet={`
                                    ${baseURL}${item.images[0]} 200w, 
                                    ${baseURL}${item.images[0]} 400w, 
                                    ${baseURL}${item.images[0]} 800w
                                `}
                                sizes="(max-width: 576px) 200px, (max-width: 768px) 400px, 800px"
                                alt="Product Image"
                                className='lazyload rounded-top-3 rounded-bottom-0 product-img'
                                loading="lazy"
                                width="200" // Set an explicit width
                                height="600" // Set an explicit height
                            />
                        </div>
                    </a>

                    <Card.Body className="px-2 py-2 w-100 d-flex justify-content-between bg-white rounded-bottom-pc">
                        <a href={`/product/${generateSlug(item?.name)}`} className='text-decoration-none text-black'>
                            <div>
                                <Card.Title className='text-start mb-0 fw-bold product-name'>{item.name}</Card.Title>
                                <Card.Text className='text-start text-black product-price'>
                                    From Rs. {item.price[0].discountedPrice}
                                </Card.Text>
                            </div>
                        </a>
                        <Button
                            type="button"
                            className="d-flex align-items-center justify-content-center bg-black mx-2 px-4 py-1 rounded-5 cursor-pointer border-0"
                            onClick={() => handleShowModal()}
                            style={{ cursor: 'pointer' }}
                        >
                            <AiOutlineShoppingCart
                                className="cart-icon"
                                style={{ color: 'white' }}
                            />
                        </Button>
                    </Card.Body>
                </Card.Body>
            </Card>
            {/* Modal for Add to Cart */}
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                show={showModal} onHide={handleCloseModal}
            >
                <Modal.Body className='bg-black'>
                    <IoCloseCircleOutline color='white' size={'24'} style={{ position: 'absolute', right: '16px', top: '12px' }} onClick={()=>handleCloseModal()} />
                    <Row className="my-4">
                        {/* only for mobile */}
                        <div className="d-md-none">
                            <Slider {...settings}>
                                {
                                    item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ?
                                        item.perfumeimages.map((perfumeimage, index) => (
                                            <div key={index}>
                                                <Image
                                                    src={`${baseURL}${perfumeimage}?w=300&h=300&fit=crop`} // Adjust width and height as needed
                                                    alt={`Perfume Image ${index + 1}`}
                                                    className='bg-transparent border-0 p-0 mb-2 mobile-product-image'
                                                    loading="lazy" // Lazy loading for mobile images
                                                />
                                            </div>
                                        ))
                                        :
                                        item.images.map((image, index) => (
                                            <div key={index}>
                                                <Image
                                                    src={`${baseURL}${image}?w=300&h=300&fit=crop`} // Adjust width and height as needed
                                                    alt={`Image ${index + 1}`}
                                                    className='bg-transparent border-0 p-0 mb-2 mobile-product-image'
                                                    loading="lazy" // Lazy loading for mobile images
                                                />
                                            </div>
                                        ))
                                }
                            </Slider>
                        </div>
                        {/* only for non mobile */}
                        <Col md={5} className="d-none d-md-block">
                            <Image
                                src={`${baseURL}${item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? perfumeMainImage : mainImage}?w=600&h=600&fit=crop`} // Adjust size for main image
                                alt={item.name}
                                fluid className='rounded-3'
                                style={{ position: 'sticky', top: '0vh' }}
                                loading="lazy" // Lazy loading for main image
                            />
                        </Col>
                        <Col md={2} className="d-none d-md-block">
                            <Row>
                                {
                                    item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ?
                                        item.perfumeimages.map((image, index) => (
                                            <Col xs={12} key={index}>
                                                <Image
                                                    src={`${baseURL}${image.replace('uploads', 'media').replace('w3prouploads', 'theattarco')}?w=300&h=300&fit=crop`} // Adjust for thumbnails
                                                    alt={`Perfume Thumbnail ${index + 1}`}
                                                    thumbnail
                                                    className='bg-transparent border-0 p-0 mb-2'
                                                    loading="lazy" // Lazy loading for thumbnails
                                                />
                                            </Col>
                                        ))
                                        :
                                        item.images.map((image, index) => (
                                            <Col xs={12} key={index}>
                                                <Image
                                                    src={`${baseURL}${image.replace('uploads', 'media').replace('w3prouploads', 'theattarco')}?w=300&h=300&fit=crop`} // Adjust for thumbnails
                                                    alt={`Thumbnail ${index + 1}`}
                                                    thumbnail
                                                    className='bg-transparent border-0 p-0 mb-2'
                                                    loading="lazy" // Lazy loading for thumbnails
                                                />
                                            </Col>
                                        ))
                                }
                            </Row>
                        </Col>
                        <Col md={5}>
                            <div style={{ position: 'sticky', top: '10vh' }}>
                                <span className="mx-2 fs-4 text-light">
                                    {item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? 'Perfume' : 'Attar'}
                                </span>
                                <h2 className='text-white'>{item.name}</h2>
                                <div className="d-flex justify-content-start align-items-center my-2">
                                    <FaWhatsapp
                                        color="#25D366"
                                        onClick={() => handleShare('whatsapp')}
                                        className="me-2 fs-4"
                                    />
                                    <FaFacebook
                                        color="#1877F2"
                                        onClick={() => handleShare('facebook')}
                                        className="me-2 fs-4"
                                    />
                                    <AiOutlineShareAlt
                                        color="#f0f0f0" // Black for a generic share icon
                                        onClick={() => handleShare('share')}
                                        className="me-2 fs-4"
                                    />
                                </div>
                                <span className={`text-${item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? 'success' : 'danger'} mx-2`}>
                                    {item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? 'In stock' : 'Out of stock'}
                                </span>
                                {item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating > 0 &&
                                    <div className='d-flex'>
                                        <StarRating rating={String(item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating)} />
                                        <div className="rating text-white  fw-bold fs-6 ms-2">{String(item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating)}/5</div>
                                    </div>
                                }
                                <span className="text-light text-decoration-line-through">Rs {item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice || item.price[0].basePrice}</span>
                                <h4 className='text-white'>Rs {item.price.find(p => p.weight + 'ml' === selectedSize)?.discountedPrice || item.price[0].discountedPrice}
                                    <span className="badge abstext-danger text-danger ms-2 rounded-5" style={{ background: '#ff333352' }}>{DiscountNearestPercentage}%</span>
                                </h4>
                                <p className='text-white' dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.description) }} ></p>

                                <h5 className='text-white mb-3'>Choose Size</h5>
                                <ButtonToolbar className="mb-4">
                                    {item.price.map((p, index) => (
                                        <Button
                                            key={index}
                                            variant={selectedSize === p.weight + 'ml' ? 'light' : 'outline-light'}
                                            onClick={() => handleSizeChange(p.weight + 'ml')}
                                            className="mx-1 rounded-5"
                                        >
                                            {p.weight}ml
                                        </Button>
                                    ))}
                                </ButtonToolbar>
                                <h5 className='text-white mb-3'>Bottle Size</h5>
                                <ButtonToolbar className="mb-5">
                                    <Button
                                        variant={'light'}
                                        className="mx-1 rounded-5"
                                    >
                                        {selectedSize}
                                    </Button>
                                </ButtonToolbar>

                                <div className="quantity-selector mb-3 d-flex">
                                    <ButtonGroup className="me-3">
                                        <Button className='bg-white text-black border-0 rounded-start-5' onClick={() => handleQuantityChange(-1)}>
                                            <IoRemoveSharp />
                                        </Button>
                                        <span className="align-self-center bg-white px-4 py-2 fw-bold">{quantity}</span>
                                        <Button className='bg-white text-black border-0 rounded-end-5' onClick={() => handleQuantityChange(1)}>
                                            <IoAddSharp />
                                        </Button>
                                    </ButtonGroup>
                                    <Button block="true" disabled={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? false : true} variant="outline-light" className='rounded-5 w-75' onClick={handleAddToCart}>Add to Cart</Button>
                                </div>
                                <Link to={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? '/checkout' : ''} state={{ item: item, isBuyNow: 'no', isCart: 'yes', selectedSize: selectedSize }} >
                                    <Button block="true" disabled={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? false : true} variant="light" className='rounded-5 w-100'>Buy Now</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <SweetAlert2
                didClose={() => {
                    setSwalProps({
                        show: false,
                    });
                }}
                {...swalProps}
            />
        </>
    );
};

export default ProductCard;

// LoaderModal.js
import React from "react";
import ReactDOM from "react-dom";
import "./Loader.css";

const Loader = ({ message = "Processing...", description = 'Please wait...', show }) => {
  if (!show) return null;

  return ReactDOM.createPortal(
    <div className="loader-modal-overlay">
      <div className="loader-modal">
        <div className="loader"></div>
        <p className="mb-0">{message}</p>
        <span className="description" style={{fontSize:'12px'}}>{description}</span>
      </div>
    </div>,
    document.body
  );
};

export default Loader;

// src/utils/useDocumentTitle.js
import { useEffect } from "react";

/**
 * Updates the document title dynamically.
 * @param {string} title - The dynamic title to set.
 * @param {string} defaultTitle - The default title if none is provided.
 */
const useDocumentTitle = (title, defaultTitle = "My Default Title") => {
  useEffect(() => {
    document.title = title || defaultTitle;
  }, [title, defaultTitle]);
};

export default useDocumentTitle;
